import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEnv } from "~/old-app/utils/helpers/getEnv";
import { CookieEnum } from "../constants";
import { RootState } from "../redux";
import { getCookie } from "../utils/helpers/cookieManager";
import { DEFAULT_LANGUAGE, I18nSupportedLngs } from "~/i18n";

export const Api = createApi({
  reducerPath: "api",
  tagTypes: [
    //////////////////////
    // remote-config tags
    //////////////////////
    "remote-config",
    "config-method-by-country",
    //////////////////////
    // service tags
    //////////////////////
    "services",
    "service",
    "service-branches",
    //////////////////////
    // branches tags
    //////////////////////
    "branches",
    "branch",
    "branch-categories",
    //////////////////////
    // branches tags
    //////////////////////
    "cart",
    "offline-cart",
    "cart-notifications",
    //////////////////////
    // addresses tags
    //////////////////////
    "addresses",
    "address",
    //////////////////////
    // addresses tags
    //////////////////////
    "quotation-orders",
    "quotation-order",
    //////////////////////
    // influencer tags
    //////////////////////
    "influencer",
    "influencers",
    "influencer-orders",
    "influencer-orders-statistics",
    "influencer-coupons",
    "influencer-products",
    //////////////////////
    // wallet tags
    //////////////////////
    "wallet",
    //////////////////////
    // system setting tags
    //////////////////////
    "static-page",
    "static-pages",
    "settings",
    "digital-product-methods",
    //////////////////////
    // countries tags
    //////////////////////
    "countries",
    "all-countries",
    "districts",
    "country-states",
    "country-cities",
    "cities",
    //////////////////////
    // sliders tags
    //////////////////////
    "sliders",
    //////////////////////
    // return orders tags
    //////////////////////
    "return-orders",
    "return-order",
    "list-return-reasons",
    "product-list-return-reasons",
    //////////////////////
    // points tags
    //////////////////////
    "my-points",
    "referral-points",
    "loyalty-activities",
    //////////////////////
    // notifications tags
    //////////////////////
    "notifications",
    "notification-general-mute",
    "update-device-id",
    //////////////////////
    // coupons tags
    //////////////////////
    "coupons",
    "coupon",
    //////////////////////
    // orders tags
    //////////////////////
    "orders",
    "order",
    //////////////////////
    // categories tags
    //////////////////////
    "categories",
    "categories-tree",
    "category",
    //////////////////////
    // rating tags
    //////////////////////
    "rates",
    "rate",
    "rate-statistics",
    //////////////////////
    // brands tags
    //////////////////////
    "brands",
    "brand",
    //////////////////////
    // products tags
    //////////////////////
    "products",
    "old-products",
    "product",
    "favorites",
    "favorites-number",
    "discount-products",
    "product-variant",
    "product-options",
    "products-search",
    //////////////////////
    // offers tags
    //////////////////////
    "offers",
    "offer",
    "offer-products",
    //////////////////////
  ],
  // extractRehydrationInfo(action, { reducerPath }): any {
  //   if (isHydrateAction(action)) {
  //     return action.payload[reducerPath]
  //   }
  // },
  baseQuery: fetchBaseQuery({
    baseUrl: getEnv().BASE_BE_API,

    prepareHeaders: (headers, { getState, endpoint }) => {
      const state = getState() as RootState;

      const token = state.auth.accessToken;

      const UPLOAD_ENDPOINTS = [
        "/upload-file/upload",
        "branchRequest",
        "sendInfluencerRequest",
      ];

      if (!headers.has("Authorization") && token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      const districtId = getCookie(CookieEnum.DistrictId)
        ? getCookie(CookieEnum.DistrictId)
        : state.config.district_id;

      if (districtId && districtId !== "undefined") {
        headers.set("district-id", districtId + "");
      }

      // get path url and get language from it
      const path = window.location.pathname;
      const pathLanguage = path.split("/")[1] || DEFAULT_LANGUAGE;

      const language = I18nSupportedLngs.includes(pathLanguage)
        ? pathLanguage
        : DEFAULT_LANGUAGE;

      // const language = getCookie(CookieEnum.Language) || DEFAULT_LANGUAGE;
      // const language = getCookie(CookieEnum.Language) || DEFAULT_LANGUAGE;
      // ? getCookie(CookieEnum.Language)
      // : state.config[CookieEnum.Language];

      if (language) {
        headers.set("language", language);
      }

      const address_id = getCookie(CookieEnum.AddressId) || null;

      if (address_id) {
        headers.set("address-id", address_id);
      }

      // user-agent-type
      headers.set("user-agent-type", "web");

      if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
        headers.set("content-type", "application/json");
      } else {
        headers.delete("content-type");
      }

      headers.set("Accept", "application/json");
      return headers;
    },
  }),

  endpoints: () => ({}),
  keepUnusedDataFor: 200,
});
