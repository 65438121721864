// utils/getEnv.js

export function getEnv() {
  if (typeof window !== "undefined" && window.ENV) {
    return window.ENV;
  }
  return {
    NODE_ENV: process.env.NODE_ENV,
    BASE_BE_API: process.env.BASE_BE_API,
    BASE_BE_URL: process.env.BASE_BE_URL,
    FAVICON_HREF: process.env.FAVICON_HREF,
    APIKEY: process.env.APIKEY,
    APP_MODE: process.env.APP_MODE,
  };
}
